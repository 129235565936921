import React from 'react';
import { Button } from '@mui/material';

interface CustomButtonProps {
  text: string;
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: () => void;
  startIcon?: React.ReactNode;
  fullWidth?: boolean;
  color?: string;
  hoverColor?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({ text, variant = 'contained', onClick, startIcon, fullWidth = true, color = '#0C68F4', hoverColor = '#0C68F4' }) => (
  <Button
    variant={variant}
    onClick={onClick}
    startIcon={startIcon}
    sx={{
      backgroundColor: variant === 'contained' ? color : undefined,
      color: variant === 'contained' ? '#fff' : color,
      borderColor: variant === 'outlined' ? color : undefined,
      '&:hover': {
        backgroundColor: variant === 'contained' ? hoverColor : undefined,
        borderColor: variant === 'outlined' ? hoverColor : undefined,
        color: variant === 'outlined' ? hoverColor : undefined,
      },
      mt: variant === 'contained' ? 2 : 0,
      mb: 2,
      width: "450px"
    }}
    fullWidth={fullWidth}
  >
    {text}
  </Button>
);

export default CustomButton;
