import React, { useState } from 'react';
import { Stack } from '@mui/joy';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import Typography from '@mui/joy/Typography';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import ShippingMethod from './shippingMethod';

const Checkout: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(1); 

  return (
    <Stack
      alignItems="center"
      mt={3}
      spacing={4}
      sx={{
        height: '100vh',
      }}
    >
      <Typography sx={{ textAlign: 'center', mb: 4, fontSize: 25, color: '#0951BE' }}>
        Hero Electro
      </Typography>
      <Stack alignItems="center" justifyContent="center" sx={{ width: '100%', maxWidth: '500px' }}>
        <Stepper
          sx={{
            width: '100%',
            '--StepIndicator-size': '3rem',
            '--Step-connectorInset': '0px',
            [`& .${stepIndicatorClasses.root}`]: {
              borderWidth: 4,
            },
            [`& .${stepClasses.root}::after`]: {
              height: 4,
            },
            [`& .${stepClasses.completed}`]: {
              [`& .${stepIndicatorClasses.root}`]: {
                borderColor: 'primary.300',
                color: 'primary.300',
              },
              '&::after': {
                bgcolor: 'primary.300',
              },
            },
            [`& .${stepClasses.active}`]: {
              [`& .${stepIndicatorClasses.root}`]: {
                borderColor: 'primary.main',
                color: 'primary.main',
              },
            },
            [`& .${stepClasses.disabled} *`]: {
              color: 'neutral.outlinedDisabledColor',
            },
          }}
        >
          
          <Step
            completed={activeStep > 0}
            onClick={() => setActiveStep(0)}
            active={activeStep === 0}
            indicator={
              <StepIndicator
                variant={activeStep === 0 ? "solid" : "outlined"}
                color={activeStep > 0 ? "primary" : activeStep === 0 ? "primary" : "neutral"}
              >
                <ShoppingCartRoundedIcon />
              </StepIndicator>
            }
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'lg',
                fontSize: '1rem',
                letterSpacing: '0.5px',
              }}
            >
              Cart
            </Typography>
          </Step>

          {/* Checkout Step */}
          <Step
            completed={activeStep > 1}
            onClick={() => setActiveStep(1)}
            active={activeStep === 1}
            indicator={
              <StepIndicator
                variant={activeStep === 1 ? "solid" : "outlined"}
                color={activeStep > 1 ? "primary" : activeStep === 1 ? "primary" : "neutral"}
              >
                <LocalShippingRoundedIcon />
              </StepIndicator>
            }
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'lg',
                fontSize: '1rem',
                letterSpacing: '0.5px',
              }}
            >
              Checkout
            </Typography>
          </Step>

          {/* Payment Step */}
          <Step
            onClick={() => setActiveStep(2)}
            active={activeStep === 2}
            indicator={
              <StepIndicator
                variant={activeStep === 2 ? "solid" : "outlined"}
                color={activeStep === 2 ? "primary" : "neutral"}
              >
                <CreditCardRoundedIcon />
              </StepIndicator>
            }
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'lg',
                fontSize: '1rem',
                letterSpacing: '0.5px',
                color: activeStep === 2 ? 'primary.main' : 'text.secondary',
              }}
            >
              Payment
            </Typography>
          </Step>
        </Stepper>
      </Stack>

      
      <ShippingMethod isCheckoutStep={activeStep === 1} />
    </Stack>
  );
};

export default Checkout;
