import { useState ,useEffect} from 'react';
import { Box, Tabs, Tab, Tooltip,TextField,CircularProgress, Avatar, IconButton, Toolbar, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, Select, AppBar, InputAdornment } from '@mui/material';
import { CalendarToday, Notifications, Search } from '@mui/icons-material';
import { InfoOutlined } from '@mui/icons-material';
import { Button } from '@mui/joy';
const orderData = [
  { id: '#6548', created: '2 min ago', customer: 'Joseph Wheeler', total: '$654', profit: '$154', profitPercent: '16%', status: 'Pending' },
  { id: '#6549', created: '10 min ago', customer: 'Michael Smith', total: '$854', profit: '$254', profitPercent: '20%', status: 'Confirmed' },
  { id: '#6550', created: '15 min ago', customer: 'Anna Johnson', total: '$354', profit: '$54', profitPercent: '8%', status: 'Processing' },
  { id: '#6551', created: '20 min ago', customer: 'Chris Lee', total: '$554', profit: '$154', profitPercent: '15%', status: 'Picked' },
  { id: '#6552', created: '25 min ago', customer: 'Jessica Brown', total: '$754', profit: '$254', profitPercent: '25%', status: 'Shipped' },
  // { id: '#6553', created: '30 min ago', customer: 'Emily Davis', total: '$954', profit: '$354', profitPercent: '35%', status: 'Delivered' },
  // { id: '#6554', created: '35 min ago', customer: 'Daniel Wilson', total: '$1154', profit: '$454', profitPercent: '40%', status: 'Cancelled' },
 
];

const statusColors: { [key: string]: string } = {
  Pending: '#fbf2d3',
  Confirmed: '#fbf2d3',
  Processing: '#fbf2d3',
  Picked: '#fbf2d3',
  Shipped: '#fbf2d3',
  Delivered: '#fbf2d3',
  Cancelled: '#fbf2d3'
};

const OrderManagement: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        // Replace with your API endpoint
        const response = await fetch('https://fake-store-api.mock.beeceptor.com/api/orders');
        const result = await response.json();
        console.log("fake api",result)
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const filteredOrders = orderData.filter(order =>
    order.id.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static" elevation={0} sx={{ borderBottom: '1px solid #ddd', backgroundColor: 'white', color: 'black' }}>
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            Order Management
          </Typography>
          <IconButton>
            <CalendarToday />
          </IconButton>
          <Typography variant="body1" sx={{ mx: 2 }}>
            28 July, 2024
          </Typography>
          <IconButton>
            <Notifications />
          </IconButton>
          <Avatar alt="Gn" src="/profile.webp" />
        </Toolbar>
      </AppBar>

      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label="Pending" sx={{ color: selectedTab === 0 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Confirmed" sx={{ color: selectedTab === 1 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Processing" sx={{ color: selectedTab === 2 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Picked" sx={{ color: selectedTab === 3 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Shipped" sx={{ color: selectedTab === 4 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Delivered" sx={{ color: selectedTab === 5 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Cancelled" sx={{ color: selectedTab === 6 ? '#4F46E5' : 'inherit' }} />
        </Tabs>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <TextField
            placeholder="Search by order id"
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={handleSearchChange}
            sx={{ width: '300px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ORDER ID</TableCell>
                <TableCell>CREATED</TableCell>
                <TableCell>CUSTOMER</TableCell>
                <TableCell>TOTAL</TableCell>
                <TableCell>PROFIT</TableCell>
                <TableCell>STATUS</TableCell>
                <TableCell align="right">ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((order, index) => (
                <TableRow key={index}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>{order.created}</TableCell>
                  <TableCell>{order.customer}</TableCell>
                  <TableCell>{order.total}</TableCell>
                  <TableCell>
                    {order.profit} 
                    <Box component="span" sx={{ backgroundColor: 'rgba(76, 175, 80, 0.2)', borderRadius: '10px', padding: '2px 8px', color: '#4CAF50', fontWeight: 'bold', display: 'inline-block' }}>
                      {order.profitPercent}
                    </Box>
                  </TableCell>
                  <TableCell>
                  <Button component="a" href="#as-link"   variant={'solid'}   
                   sx={{ bgcolor: statusColors[order.status] || 'transparent' ,color:'#FFC600',fontWeight:'bold'}}>
                  {order.status}
                  </Button>
                  <Tooltip
        title={
          loading ? (
            <CircularProgress size={24} />
          ) : (
            data ? data[index]['status'] : 'No data available'
          )
        }
        // title={"Yo"}
        // arrow
      >
        <IconButton aria-label="Open in new tab" component="a" href="#as-link">
          <InfoOutlined />
        </IconButton>
      </Tooltip>
                    {/* <Select
                      value={order.status}
                      displayEmpty
                      variant="outlined"
                      size="small"
                      sx={{ bgcolor: statusColors[order.status] || 'transparent' ,color:'#FFC600',fontWeight:'bold'}}
                    >
                      {Object.keys(statusColors).map(status => (
                        <MenuItem key={status} value={status}>{status}</MenuItem>
                      ))}
                    </Select> */}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton>
                      <span>↻</span>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Typography variant="body2">Showing {filteredOrders.length} of {orderData.length}</Typography>
          <Box>
            <IconButton sx={{ color: '#4F46E5' }}>⟨</IconButton>
            {[1, 2, 3, 4, 5].map((page) => (
              <IconButton key={page} sx={{ color: '#4F46E5' }}>{page}</IconButton>
            ))}
            <IconButton sx={{ color: '#4F46E5' }}>⟩</IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderManagement;
