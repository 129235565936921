import React from 'react';
import Button from '@mui/joy/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

interface IconButtonProps {
  onPress: () => void;
  title: string;
  iconname?: string;
  sx?: object; 
}

const IconButton: React.FC<IconButtonProps> = ({ title, iconname, onPress, sx }) => {
  const renderIcon = () => {
    switch (iconname) {
      case 'cart':
        return <ShoppingCartIcon sx={{ marginRight: '8px' }} />;
      case 'login':
        return <AccountCircleIcon sx={{ marginRight: '8px' }} />;
      default:
        return null;
    }
  };

  return (
    <Button onClick={onPress} sx={sx}>
      {iconname && renderIcon()}
      {title}
    </Button>
  );
}

export default IconButton;
