import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { Stack } from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const StyledPaper = styled(Paper)({
  padding: '16px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  backgroundColor: '#fff',
});

const ImageItem = ({ name }: { name: string }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', p: 1, border: '1px solid #ddd', borderRadius: '4px' }}>
    <img src="/productManagement.svg" alt="Product thumbnail" style={{ width: '50px', marginRight: '8px' }} />
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="body2" color="textSecondary">
        {name}
      </Typography>
      <LinearProgress variant="determinate" value={100} sx={{ height: '6px', borderRadius: '2px', mt: 1 }} />
    </Box>
    <Box sx={{ ml: '8px' }}>
      <CheckCircleIcon color="primary" />
    </Box>
  </Box>
);

const ProductForm: React.FC<{ onBack: () => void }> = ({ onBack }) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const imageInputRef = useRef<HTMLInputElement | null>(null);


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'image' | 'file') => {
    const file = event.target.files?.[0];
    if (file) {
      if (type === 'image') {
        setSelectedImage(file);
      } else if (type === 'file') {
        setSelectedFile(file);
      }
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleSubmit = () => {
    if (selectedFile) {
      setLoading(true);
     
      setTimeout(() => {
        console.log('Submitting file:', selectedFile);
        setLoading(false);
      }, 2000);
    }
  };

  const handleImageClick = () => {
    imageInputRef.current?.click();
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Add Product</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <StyledPaper sx={{ flex: 1 }}>
              <Typography variant="h6" gutterBottom>
                Product Name
              </Typography>
              <TextField fullWidth variant="outlined" size="small" sx={{ mb: 2 }} />

              <Typography variant="h6" gutterBottom>
                Description
              </Typography>
              <TextField fullWidth variant="outlined" size="small" multiline rows={4} sx={{ mb: 2 }} />

              <Typography variant="h6" gutterBottom>
                Category
              </Typography>
              <TextField fullWidth variant="outlined" size="small" sx={{ mb: 2 }} />

              <Typography variant="h6" gutterBottom>
                Number of Holes
              </Typography>
              <TextField fullWidth variant="outlined" size="small" sx={{ mb: 2 }} />

              <Typography variant="h6" gutterBottom>
                Type of Sheet
              </Typography>
              <TextField fullWidth variant="outlined" size="small" sx={{ mb: 2 }} />

              <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>
                    Regular Price
                  </Typography>
                  <TextField variant="outlined" size="small" sx={{ mb: 2, width: 360 }} />
                </Stack>

                <Stack sx={{ display: 'flex', flexDirection: 'column', ml: 10 }}>
                  <Typography variant="h6" gutterBottom>
                    Sale Price
                  </Typography>
                  <TextField variant="outlined" size="small" sx={{ mb: 2, width: 360 }} />
                </Stack>
              </Stack>

              <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>
                    Discount
                  </Typography>
                  <TextField variant="outlined" size="small" sx={{ mb: 2, width: 360 }} />
                </Stack>

                <Stack sx={{ display: 'flex', flexDirection: 'column', ml: 10 }}>
                  <Typography variant="h6" gutterBottom>
                    Stock Quantity
                  </Typography>
                  <TextField variant="outlined" size="small" sx={{ mb: 2, width: 360 }} />
                </Stack>
              </Stack>
            </StyledPaper>

            <Box sx={{ width: '35%' }}>
              <StyledPaper sx={{ mb: 2, textAlign: 'center' }}>
                <img src="/productManagement.svg" alt="Product" style={{ width: '80%' }} />
              </StyledPaper>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>
                  Product Gallery
                </Typography>
                <Box
                  sx={{
                    border: '2px dashed #ddd',
                    borderRadius: '4px',
                    p: 2,
                    textAlign: 'center',
                    mb: 2,
                    cursor: 'pointer',
                  }}
                  onClick={handleImageClick}
                >
                  <img src="/ph_image-light.svg" alt="Product" style={{ width: '20%' }} />
                  <Typography variant="body1" color="textSecondary">
                    Drop your image here, or browse
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Jpeg, png are allowed
                  </Typography>
                  <input
                    type="file"
                    accept="image/*"
                    ref={imageInputRef}
                    style={{ display: 'none' }}
                    onChange={(event) => handleFileChange(event, 'image')}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {selectedImage && <ImageItem name={selectedImage.name} />}
                  <ImageItem name="Product thumbnail.png" />
                  <ImageItem name="Product thumbnail.png" />
                  <ImageItem name="Product thumbnail.png" />
                </Box>
              </StyledPaper>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button variant="contained" color="primary" sx={{ width: '32%' }}>
                  UPDATE
                </Button>
                <Button variant="contained" color="error" sx={{ width: '32%' }}>
                  DELETE
                </Button>
                <Button variant="contained" color="inherit" sx={{ width: '32%' }} onClick={onBack}>
                  CANCEL
                </Button>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ marginTop: 5 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Bulk Upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 2, p: 2 }}>
            {selectedFile ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  padding: '8px',
                }}
              >
                <Typography variant="body1">{selectedFile.name}</Typography>
                <IconButton onClick={handleRemoveFile} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <label htmlFor="upload-excel" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 1 }}>
                <img src="/xlicon.webp" alt="Excel" style={{ width: '120px' }} />
                <input
                  type="file"
                  id="upload-excel"
                  accept=".xlsx, .xls"
                  style={{ display: 'none' }}
                  onChange={(event) => handleFileChange(event, 'file')}
                />
              </label>
            )}
            {!selectedFile && (
              <Typography variant="body1" sx={{ color: '#bab7bc', fontSize: '20px' }}>
                Click this icon to upload an Excel file for bulk product upload...
              </Typography>
            )}
            {selectedFile && (
              <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2, backgroundColor: '#4F46E5', width: '100px' }}>
                {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Upload'}
              </Button>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ProductForm;
