import React from 'react';
import { Box } from '@mui/joy';
import { BigCard } from '../../assets/images';

const Banner: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${BigCard})`,
        height: '80vh', 
        width: '100%', 
        maxWidth: '1600px',
        margin: '0 auto',
        borderRadius: '6px',
        backgroundSize: 'contain',
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat', 
      }}
    />
  );
};

export default Banner;
