import React, { useState } from 'react';
import { Box, Typography, Button, CardContent, Stack, Divider, Grid, Checkbox, RadioGroup } from '@mui/joy';
import { FormControlLabel, IconButton, Radio } from '@mui/material';
import { FavoriteBorder, ShoppingCart } from '@mui/icons-material';
import telegramIcon from '../../assets/socialmedia_icons/telegram.png';
import FacebookIcon from '../../assets/socialmedia_icons/facebook.png';
import InstagramIcon from '../../assets/socialmedia_icons/instagram.png';
import Whatsapp from '../../assets/socialmedia_icons/whatsapp.png';
import Twitter from '../../assets/socialmedia_icons/twitter.png';

const QuantitySelector: React.FC = () => {
    const [quantity, setQuantity] = React.useState(1);

    const handleIncrease = () => {
        setQuantity(quantity + 1);
    };

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '12px', p: 1 }}>
            <IconButton onClick={handleDecrease}>
                <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>−</Typography>
            </IconButton>
            <Typography sx={{ mx: 2 }}>{quantity}</Typography>
            <IconButton onClick={handleIncrease}>
                <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>+</Typography>
            </IconButton>
        </Box>
    );
};

const ProductDetails: React.FC = () => {
    const [zoomPosition, setZoomPosition] = useState<{ x: number; y: number } | null>(null);

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const { offsetX, offsetY, target } = e.nativeEvent as MouseEvent & {
            target: HTMLDivElement;
        };

        const { width, height } = target.getBoundingClientRect();

        const x = (offsetX / width) * 100;
        const y = (offsetY / height) * 100;

        setZoomPosition({ x, y });
    };

    const handleMouseLeave = () => {
        setZoomPosition(null);
    };

    return (
        <Stack sx={{ maxWidth: 1200, mx: 'auto', my: 4, p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Typography sx={{ fontSize: '0.875rem', color: '#777' }}>
                    Home &gt; POLY SHEETS &gt; CREATIVE DESIGNS &gt; ANIMALS &gt; HEANML11.2 MONKEY DRIVE CYCLE WITH BORDER 4X6 FEET
                </Typography>
            </Box>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                            <Box
                                onMouseMove={handleMouseMove}
                                onMouseLeave={handleMouseLeave}
                                sx={{
                                    width: '120%',
                                    height: '550px',
                                    backgroundImage: `url('./proguctimage.png')`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    transition: 'transform 0.3s ease',
                                    cursor: 'crosshair',
                                }}
                            />
                        {zoomPosition && (
    <Box
        sx={{
            position: 'absolute',
            top: 0,
            left: '102%',
            width: '780px',
            height: '700px',
            border: '1px solid gray',
            backgroundImage: `url('./proguctimage.png')`,
            backgroundSize: '200%', 
            backgroundPosition: `${zoomPosition.x}% ${zoomPosition.y}%`,
            backgroundRepeat: 'no-repeat',
            borderWidth: 2,
            boxShadow: '6px 10px 20px rgba(0, 0, 0, 0.25), 6px 6px 6px rgba(0, 0, 0, 0.22)', 
            zIndex: 10, 
            backgroundColor: '#fff',
        }}
    />
)}

                        </Box>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
                            <img
                                src="./proguctimage.png"
                                alt="Thumbnail 1"
                                style={{ width: 100, height: 100 }}
                            />
                            <img
                                src="./proguctimage.png"
                                alt="Thumbnail 2"
                                style={{ width: 100, height: 100 }}
                            />
                            <img
                                src="./proguctimage.png"
                                alt="Thumbnail 3"
                                style={{ width: 100, height: 100 }}
                            />
                            <img
                                src="./proguctimage.png"
                                alt="Thumbnail 4"
                                style={{ width: 100, height: 100 }}
                            />
                        </Box>
                        <Box sx={{ mt: 2, borderRadius: 2, overflow: 'hidden', border: '1px solid #e0e0e0' }}>
                            <Grid container sx={{ backgroundColor: '#f9f9f9', padding: '8px 16px' }}>
                                <Grid xs={6}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Weight</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>2 kg</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ padding: '8px 16px' }}>
                                <Grid xs={6}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Holes Count</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>1088</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ backgroundColor: '#f9f9f9', padding: '8px 16px' }}>
                                <Grid xs={6}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Measurement</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>4X6 FEET</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ padding: '8px 16px' }}>
                                <Grid xs={6}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Required Pixel LED (Holes/50)</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>22 SETS</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '0.75rem', color: '#777', mr: 1 }}>
                                Did you like this product? Add to favorites now and follow the product.
                            </Typography>
                            <IconButton aria-label="add to favorites" sx={{ ml: 10 }}>
                                <FavoriteBorder />
                            </IconButton>
                        </Box>
                        <Typography level="h4">HEANML11.2 MONKEY DRIVE CYCLE WITH BORDER 4X6 FEET</Typography>
                        <Typography sx={{ fontSize: 20, mb: 2, color: 'gray' }}>
                            ₹1,717.00 <s style={{ fontSize: 25, color: 'red', fontWeight: 'bold' }}>₹1,752.00</s>
                        </Typography>

                        <Typography sx={{ mb: 2, color: 'gray' }}>
                            A framed depiction of a monkey on a bicycle, outlined by 1088 meticulously arranged perforations.
                        </Typography>

                        <Box sx={{ mt: 4, textAlign: 'start' }}>
                            <Typography sx={{ fontWeight: 'bold', mb: 2 }}>Share with Your Loved Ones</Typography>

                            <Box>
                                <img src={Whatsapp} alt="WhatsApp" style={{ width: 40, height: 40, margin: 3 }} />
                                <img src={InstagramIcon} alt="Instagram" style={{ width: 40, height: 40, margin: 5 }} />
                                <img src={FacebookIcon} alt="Facebook" style={{ width: 40, height: 40, margin: 5 }} />
                                <img src={telegramIcon} alt="Telegram" style={{ width: 40, height: 40, margin: 5 }} />
                                <img src={Twitter} alt="Twitter" style={{ width: 40, height: 40, margin: 5 }} />
                            </Box>
                        </Box>

                        <Box sx={{ my: 2 }}>
                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>
                                Poly Sheet <span style={{ color: 'red' }}>*</span>
                            </Typography>

                            <RadioGroup defaultValue="required">
                                <FormControlLabel value="required" control={<Radio />} label="Required" />
                                <FormControlLabel value="not-required" control={<Radio />} label="Not Required –₹1,489.00" />
                            </RadioGroup>
                        </Box>

                        <Box sx={{ my: 2 }}>
                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>Poly Sheet Color <span style={{ color: 'red' }}>*</span></Typography>
                            <RadioGroup defaultValue="black">
                                <FormControlLabel value="black" control={<Radio />} label="Black" />
                                <FormControlLabel value="white" control={<Radio />} label="White +₹175.00" />
                            </RadioGroup>
                        </Box>

                        <Box sx={{ my: 2 }}>
                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>Poly Sheet Size <span style={{ color: 'red' }}>*</span></Typography>
                            <RadioGroup defaultValue="09mm">
                                <FormControlLabel value="09mm" control={<Radio />} label="09 MM (12V SINGLE COLOR PIXEL LED)" />
                                <FormControlLabel value="12mm" control={<Radio />} label="12 MM (5V MULTI-COLOR PIXEL LED)" />
                            </RadioGroup>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>Additional Products and Prices</Typography>
                            <Checkbox label="DATA (CONTROLLERS) +₹18,008.00" />
                            <Checkbox label="9 MM FOR (12V SINGLE COLOR PIXEL LED) +₹1,958.00" />
                            <Checkbox label="12 MM FOR (5V MULTI-COLOR PIXEL LED) +₹6,310.00" />
                            <Checkbox label="Power Supply(SMPS) +₹1,958.00" />
                            <Checkbox label="Frame Cost +₹3,600.00" />
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>Additional Services and Prices</Typography>
                            <Checkbox label="Wiring (Pixel LED) and labor costs +₹2,394.00" />
                        </Box>

                        <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
                            <QuantitySelector />

                            <Button
                                variant="solid"
                                color="primary"
                                size="lg"
                                startDecorator={<ShoppingCart />}
                                sx={{
                                    backgroundColor: '#007bff',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    borderRadius: '12px',
                                }}
                            >
                                Add to cart
                            </Button>

                            <Button
                                variant="outlined"
                                color="neutral"
                                size="lg"
                                sx={{
                                    borderColor: '#e0e0e0',
                                    color: '#007bff',
                                    padding: '10px 20px',
                                    borderRadius: '12px',
                                }}
                            >
                                Buy Now
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ mt: 4, textAlign: 'center' }}>
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/yourvideoid"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Box>
                    <Box sx={{ textAlign: 'start', mt: 4, ml: 4 }}>
                        <Typography sx={{ mb: 2, fontStyle: 'italic' }}>
                            <span style={{ color: 'red' }}>*</span> NO CASH ON DELIVERY
                        </Typography>
                        <Typography sx={{ fontStyle: 'italic', mb: 2 }}>
                            <span style={{ color: 'red' }}>*</span> Expected delivery via courier service within 3 to 5 business days
                        </Typography>
                        <Typography sx={{ fontStyle: 'italic', mb: 2 }}>
                            <span style={{ color: 'red' }}>*</span> Please contact us via WhatsApp (+91 90421 65139) with the product name or product ID if you are from outside of India!
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{ my: 4 }} />
                <Typography sx={{ mb: 2, mt: 2, fontStyle: 'italic' }}>
                    HOLES SIZE: 09MM (12V SINGLE COLOR PIXEL LED), 12MM (5V MULTI-COLOR PIXEL LED) | SHEET THICKNESS: 1.2MM (FLEXIBLE PLASTIC WATERPROOF)
                </Typography>
            </CardContent>
        </Stack>
    );
};

export default ProductDetails;
