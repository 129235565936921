import React from 'react';
import { Grid, Card, CardContent, Typography, Box, IconButton, Avatar,  AppBar, Toolbar, InputAdornment, TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import {  Notifications, CalendarToday, Search } from '@mui/icons-material';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';

const DashboardContent: React.FC = () => {

  const cards = [
    { title: 'Total Products', value: '$350.40', image: '/totalProducts.svg' },
    { title: 'Completed Orders', value: '$642.39', image: '/orders.svg' },
    { title: 'Cancelled Orders', value: '$642.39', image: '/orders.svg' },
    { title: 'Today Revenue', value: '$2,000.00', image: '/Flag.svg '},
    { title: 'Top Products', value: '154', image: '/topProducts.svg' },
  ];

  const salesData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    datasets: [
      {
        label: 'Revenue',
        data: [1200, 1900, 3000, 5000, 2300, 3400, 7000, 9000, 11000],
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const transactions = [
    { id: 24567, item: 'Light Set', category: 'Lights', date: '22-07-2024', price: '₹299', payment: 'UPI', status: 'Success' },
    { id: 24568, item: 'Light Set', category: 'Lights', date: '27-07-2024', price: '₹299', payment: 'Debit Card', status: 'Success' },
    { id: 24569, item: 'Light Set', category: 'Lights', date: '16-07-2024', price: '₹299', payment: 'Credit Card', status: 'Success' },
    { id: 24570, item: 'Light Set', category: 'Lights', date: '06-07-2024', price: '₹299', payment: 'Phone Pay', status: 'Success' },
    { id: 24571, item: 'Light Set', category: 'Lights', date: '20-07-2024', price: '₹299', payment: 'COD', status: 'Success' },
  ];

  const mostPurchasedProductsData = {
    labels: ['Light1', 'Light2', 'Light3', 'Light4', 'Light5', 'Light6'],
    datasets: [{
      label: 'Products',
      data: [100, 150, 200, 120, 130, 140],
      backgroundColor: [
        'rgba(75, 192, 192, 0.5)',
        'rgba(192, 75, 75, 0.5)',
        'rgba(75, 75, 192, 0.5)',
        'rgba(192, 192, 75, 0.5)',
        'rgba(75, 192, 75, 0.5)',
        'rgba(192, 75, 192, 0.5)'
      ]
    }]
  };

 

  return (
    <Box sx={{ display: 'flex',  }}>
     

      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
        <AppBar position="static"  elevation={0} sx={{ borderBottom: '1px solid #ddd', mb: 2 ,backgroundColor:"white",color:'black'}}>
          <Toolbar>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
              Good Morning, John!
            </Typography>
            
            <IconButton>
              <CalendarToday />
            </IconButton>
            <Typography variant="body1" sx={{ mx: 2 }}>
              28 July, 2024
            </Typography>
            <IconButton>
              <Notifications />
            </IconButton>
            <Avatar alt="Gn" src="/profile.webp" />
          </Toolbar>
        </AppBar>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {cards.map((card, index) => (
              <Grid item xs={12} sm={6} md={2.4} key={index}>
                <Card>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src={card.image} alt={card.title} style={{ width: '43px', marginRight: '16px' }} />
                      <Box>
                        <Typography color="textSecondary" gutterBottom>
                          {card.title}
                        </Typography>
                        <Typography variant="h5" component="div">
                          {card.value}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" component="div">
                        Your Sales Report
                      </Typography>
                      <Typography variant="h4" component="div" sx={{ mt: 2 }}>
                        ₹ 7000.80
                      </Typography>
                      <Typography variant="body2" color="#00D03A">
                        ₹ 2500.00 (+3.5%)
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" color="textSecondary">
                          1D 1W <span style={{ color: '#3f51b5', fontWeight: 'bold' }}>1M</span> 6M 1Y
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" component="div">
                        Revenue Year - 2024
                      </Typography>
                      <Line data={salesData} height={100} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }} direction="row">
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="div">
                    Last Transactions
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <TextField
                      placeholder="Search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Order ID</TableCell>
                          <TableCell>Item</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Type of Payment</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactions.map((transaction) => (
                          <TableRow key={transaction.id}>
                            <TableCell>{transaction.id}</TableCell>
                            <TableCell>{transaction.item}</TableCell>
                            <TableCell>{transaction.category}</TableCell>
                            <TableCell>{transaction.date}</TableCell>
                            <TableCell>{transaction.price}</TableCell>
                            <TableCell>{transaction.payment}</TableCell>
                            <TableCell><Typography color="green">{transaction.status}</Typography></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="div">
                    Most Purchased Products
                  </Typography>
                  <Bar data={mostPurchasedProductsData} />
                </CardContent>
              </Card>
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    Present Discount
                  </Typography>
                  <Typography variant="h4" component="div" sx={{ mt: 2 }}>
                    40%
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    Total Users
                  </Typography>
                  <Typography variant="h4" component="div" sx={{ mt: 2 }}>
                    200
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardContent;
