import React, { useState } from 'react';
import { Stack, Typography, Box, RadioGroup, Button } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import { FormControlLabel, IconButton, Modal, Radio as MuiRadio, Radio, TextField } from '@mui/material';
import YourOrder from './yourOrder';
import { Add, Close } from '@mui/icons-material';
import AddressModal from './mapModal';

interface ShippingMethodOption {
  label: string;
  description: string;
  price: string;
  
}

interface ShippingMethodProps {
  isCheckoutStep: boolean; 
}

const ShippingMethod: React.FC<ShippingMethodProps> = ({ isCheckoutStep }) => {
  const [selectedMethod, setSelectedMethod] = useState<string>('express');
  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMethod(event.target.value);
  };


  const shippingOptions: ShippingMethodOption[] = [
    { label: 'Free Shipping', description: '7-30 business days', price: '$0' },
    { label: 'Regular Shipping', description: '3-14 business days', price: '$7.50' },
    { label: 'Express Shipping', description: '1-3 business days', price: '$22.50' },
  ];

    function setIsCheckout(arg0: boolean): void {
        throw new Error('Function not implemented.');
    }
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('HubSpot, 25 First Street, Cambridge MA 02141, United States');
    const handleConfirm = (location: string) => {
      setSelectedLocation(location);
      setModalOpen(false);
    };
    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };
  
    const handleOpenEditModal = () => {
      setOpenEditModal(true);
    };
  
    const handleCloseEditModal = () => {
      setOpenEditModal(false);
    };
    const [cardNumber, setCardNumber] = useState('');

    const handleCardNumberChange = (event: { target: { value: string; }; }) => {
      let value = event.target.value.replace(/\D/g, ''); 
      value = value.match(/.{1,4}/g)?.join('-') || ''; 
      setCardNumber(value);
    };

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box
        sx={{
          p: 3,
          width: '100%',
          maxWidth: '500px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          bgcolor: '#fff',
          mr: 4,
        }}
      >
        <Stack spacing={2}>
          {isCheckoutStep ? (
            <>
              <Typography sx={{ fontWeight: 'bold', color: '#757575' }}>User</Typography>
              <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '8px', border: '1px solid #E0E0E0' }}>
                <Typography sx={{ mt: 1, color: '#212121' }}>GN</Typography>
              </Box>

              <Typography sx={{ fontWeight: 'bold', color: '#757575' }}>Ship to</Typography>
              <Box
                sx={{
                  bgcolor: '#FAFAFA',
                  p: 2,
                  borderRadius: '8px',
                  border: '1px solid #E0E0E0',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
               <Box sx={{ display: 'flex', alignItems: 'center', color: '#212121' }}>
        <Typography>{selectedLocation}</Typography>
        <EditIcon
          sx={{ ml: 1, fontSize: 'large', color: '#757575', cursor: 'pointer' }}
          onClick={() => setModalOpen(true)}
        />
      </Box>
              </Box>

              <Typography sx={{ fontWeight: 'bold', color: '#757575' }}>Shipping Method</Typography>
              <RadioGroup value={selectedMethod} onChange={handleChange} sx={{ mt: 1 }}>
                {shippingOptions.map((option, index) => {
                  const isSelected = selectedMethod === option.label.toLowerCase().replace(' ', '');
                  return (
                    <FormControlLabel
                      key={option.label}
                      value={option.label.toLowerCase().replace(' ', '')}
                      control={<MuiRadio sx={{ marginRight: 2 }} />}
                      label={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            <Typography
                              sx={{
                                color: '#212121',
                                fontWeight: isSelected ? 'bold' : 'normal',
                              }}
                            >
                              {option.label}
                            </Typography>
                            <Typography
                              sx={{
                                color: '#757575',
                                fontSize: '0.875rem',
                              }}
                            >
                              {option.description}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              color: '#757575',
                              fontWeight: isSelected ? 'bold' : 'normal',
                            }}
                          >
                            {option.price}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        py: 1,
                        px: 2,
                        mt: index !== 0 ? 2 : 0,
                        borderRadius: '8px',
                        border: `1px solid ${isSelected ? '#0D47A1' : '#E0E0E0'}`,
                        bgcolor: isSelected ? '#E3F2FD' : '#FAFAFA',
                        transition: 'background-color 0.3s, border-color 0.3s',
                      }}
                    />
                  );
                })}
              </RadioGroup>
              
              <Typography sx={{ color: '#0D47A1', cursor: 'pointer', mt: 2 }}>Return to cart</Typography>
            </>
          ) : (
            <>
            <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>Payment</Typography>
      
            <RadioGroup>
              <FormControlLabel
                value="creditCard"
                control={<MuiRadio />}
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      bgcolor: '#FAFAFA',
                      borderRadius: '8px',
                      p: 2,
                    }}
                  >
                    <Typography>Credit Cards</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ mr: 1 }}>AMERICAN EXPRESS</Typography>
                      <IconButton onClick={handleOpenEditModal}>
                  <EditIcon sx={{ fontSize: 'large', color: '#0951BE' }} />
                </IconButton>
                      <IconButton
                        sx={{
                          ml: 1,
                          bgcolor: '#E3F2FD',
                          borderRadius: '20%',
                          minWidth: '32px',
                          height: '32px',
                          color: 'black',
                        }}
                        onClick={handleOpenModal}
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  </Box>
                }
                sx={{
                  mt: 2,
                  bgcolor: '#FAFAFA',
                  p: 2,
                  borderRadius: '8px',
                  border: '1px solid #E0E0E0',
                }}
              />
      
              <FormControlLabel
                value="paypal"
                control={<MuiRadio />}
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      bgcolor: '#FAFAFA',
                      borderRadius: '8px',
                      p: 2,
                    }}
                  >
                    <Typography>PayPal</Typography>
                  </Box>
                }
                sx={{
                  mt: 2,
                  bgcolor: '#FAFAFA',
                  p: 2,
                  borderRadius: '8px',
                  border: '1px solid #E0E0E0',
                }}
              />
            </RadioGroup>
      
            <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem', mt: 3 }}>Billing address</Typography>
      
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: '#FAFAFA',
                p: 2,
                borderRadius: '8px',
                border: '1px solid #E0E0E0',
                mt: 2,
              }}
            >
              <Typography>Same as shipping address</Typography>
              <EditIcon sx={{ fontSize: 'small', color: '#0951BE' }} />
            </Box>
      
            <Typography
              sx={{ color: '#0D47A1', cursor: 'pointer', mt: 3 }}
              onClick={() => setIsCheckout(true)}
            >
              Return to checkout
            </Typography>
      
            <Modal open={openModal} onClose={handleCloseModal}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  bgcolor: 'background.paper',
                  borderRadius: '8px',
                  boxShadow: 24,
                  p: 4,
                  backgroundColor:'#fff'
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography  component="h2" sx={{ mb: 2 }}>
                    Add your payment method
                  </Typography>
                  <IconButton onClick={handleCloseModal}>
                    <Close />
                  </IconButton>
                </Box>
                <Typography sx={{ mb: 2 }}>
                 Credit or debit cards
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  Tech Heim accepts major credit and debit cards.
                </Typography>
                <TextField
      fullWidth
      label="Card number"
      variant="outlined"
      value={cardNumber}
      onChange={handleCardNumberChange}
      inputProps={{
        maxLength: 19, 
      }}
      sx={{ mb: 2 }}
    />
                <TextField
                  fullWidth
                  label="Name on card"
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    label="Expiration date (MM/YY)"
                    variant="outlined"
                    sx={{ mb: 2, flex: 1 }}
                    defaultValue="07/25"
                  />
                  <TextField
                    label="CVV"
                    variant="outlined"
                    sx={{ mb: 2, flex: 1 }}
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={handleCloseModal} sx={{ mr: 1 }}>Cancel</Button>
                  <Button  onClick={handleCloseModal}>Save</Button>
                </Box>
              </Box>
            </Modal>
            <Modal open={openEditModal} onClose={handleCloseEditModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 3,
            backgroundColor:'#fff'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Your credit and debit cards
            </Typography>
            <IconButton onClick={handleCloseEditModal}>
              <Close />
            </IconButton>
          </Box>
          <RadioGroup>
            <FormControlLabel
              value="card1"
              control={<Radio />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px dashed #E0E0E0', p: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    
                    <Typography>1002 300004 50006</Typography>
                  </Box>
                  <Typography>J SMITH</Typography>
                  <Typography>12/24</Typography>
                </Box>
              }
            />
            <FormControlLabel
              value="card2"
              control={<Radio />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px dashed #E0E0E0', p: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    
                    <Typography>4763 5007 0001 3456</Typography>
                  </Box>
                  <Typography>J SMITH</Typography>
                  <Typography>10/27</Typography>
                </Box>
              }
            />
            <FormControlLabel
              value="card3"
              control={<Radio />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px dashed #E0E0E0', p: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                   
                    <Typography>5258 9712 3456 7890</Typography>
                  </Box>
                  <Typography>J SMITH</Typography>
                  <Typography>07/24</Typography>
                </Box>
              }
            />
          </RadioGroup>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, borderTop: '1px dashed #E0E0E0', pt: 2 }}>
            <IconButton sx={{ mr: 1 }}>
              <Add />
            </IconButton>
            <Typography sx={{ fontWeight: 'bold' }}>add new card</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button onClick={handleCloseEditModal} sx={{ color: '#0D47A1' }}>Cancel</Button>
            <Button  onClick={handleCloseEditModal} sx={{ backgroundColor: '#0D47A1' }}>Save</Button>
          </Box>
        </Box>
      </Modal>
          </>
          )}
        </Stack>
      </Box>

      
      <YourOrder />
      <AddressModal open={isModalOpen} onClose={() => setModalOpen(false)}  onConfirm={handleConfirm}/>
    </Stack>
  );
};

export default ShippingMethod;
