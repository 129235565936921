import React, { useState, useEffect, useRef } from 'react';
import { Box, styled } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import CustomCard from '../Button/customCard';
import { SteelImage1,SteelCoil,SteelBars,StainlessSteelCoil,
  CarbonSteelBar,CarbonSteelCoil,SeamlessStellTube,SeamlessCarbonSteelpipe,
  CarbonSteelPlate,GlvaniedSteel,StainlessSteelAngleSteel } from '../../assets/images';

const data = [
  {
    title: 'Pipes',
    image: SteelImage1,
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'Steel Coil',
    image: SteelCoil,
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'Iron',
    image: SteelBars,
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: '202 Stainless Steel Coil',
    image: StainlessSteelCoil,
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'A36 Carbon Steel Bar',
    image: CarbonSteelBar,
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'A36 Carbon Steel Coil',
    image: CarbonSteelCoil,
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'A179 Seamless Steel Tube',
    image: SeamlessStellTube,
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'A213 Seamless Carbon Steel Pipe',
    image: SeamlessCarbonSteelpipe,
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
];

const CarouselContainer = styled(Box)({
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  width: 'calc(86% - 20px)', 
  margin: '0 auto', 
});

const InnerContainer = styled(Box)({
  display: 'flex',
  transition: 'transform 0.5s ease-in-out',
  flexDirection: 'row',
  width: '100%',
});

const CardWrapper = styled(Box)({
  flex: '2%',
  boxSizing: 'border-box',
  cursor: 'pointer',
});

const Indicators = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

const Indicator = styled(Box)<{ active: boolean }>(({ active }) => ({
  height: '10px',
  width: '10px',
  borderRadius: '50%',
  backgroundColor: active ? '#F44336' : 'lightgray',
  margin: '0 5px',
  cursor: 'pointer',
}));

const Cards: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef<number | null>(null);
  const navigate = useNavigate();  

  const extendedData = [...data, ...data, ...data];

  useEffect(() => {
    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % (data.length + 5));
    };

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(nextSlide, 9000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [currentIndex]);

  const handleIndicatorClick = (index: number) => {
    setCurrentIndex(index);
  };

  const handleCardClick = (index: number) => {
    // navigate(`/productdetails`); 
  };

  return (
    <Box>
      <CarouselContainer>
        <InnerContainer
        
          style={{
            transform: `translateX(-${currentIndex * 20}%)`,
          }}
        >
          {extendedData.map((item, index) => (
            <CardWrapper key={index} onClick={() => handleCardClick(index)}>
              <CustomCard
              
                title={item.title}
                image={item.image}
                originalPrice={item.originalPrice}
                discountedPrice={item.discountedPrice}
                discount={item.discount}
              />
            </CardWrapper>
          ))}
        </InnerContainer>
      </CarouselContainer>
      <Indicators>
        {Array.from({ length: data.length + 5 }).map((_, index) => (
          <Indicator
            key={index}
            active={index === currentIndex}
            onClick={() => handleIndicatorClick(index)}
            sx={{ mb: 4 }}
          />
        ))}
      </Indicators>
    </Box>
  );
};

export default Cards;
