import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Header, Footer } from './components';
import Home from './components/landingPage/Home';
import Login from './pages/Auth/Login/login';
import Register from './pages/Auth/Login/register';
import AdminLogin from './pages/Admin/Auth/Login/adminLogin';
import AdminDashboard from './pages/Admin/Auth/Dashboard/dashboard';
import OrderTracking from './components/payment/orderTracking';
import Checkout from './components/payment/checkoutPage';
import ProductDetails from './components/landingPage/product-details';

const App = () => {
  const location = useLocation();
  const handleLoginClick = () => {
    console.log("Login Clicked");
  }

  const handleSearchClick = () => {
    console.log("Search Clicked");
  }

  const handleCustomizeClick = () => {
    console.log("Customize Clicked");
  }

  const shouldShowHeader = !['/adminLogin', '/register', '/dashboardContent','/login','/dashboard'].includes(location.pathname);
  const shouldShowFooter = !['/adminLogin', '/register', '/dashboardContent','/login','/dashboard'].includes(location.pathname);

  return (
    <div style={{overflow:'hidden'}}>
    <>
      {shouldShowHeader && (
        <Header
          logo={require('./assets/images/logo.jpg')}
          onLoginClick={handleLoginClick}
          onSearchClick={handleSearchClick}
          onCustomizeClick={handleCustomizeClick}
        />
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/dashboard" element={ <AdminDashboard/>} />
        <Route path="/order-tracking" element={<OrderTracking />} />
        <Route path="/checkoutpage" element={<Checkout />} />
        <Route path="/productdetails" element={<ProductDetails />} />
      </Routes>

      {shouldShowFooter && (  <Footer />)}
    
    </>
    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
