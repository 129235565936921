
import { Typography, Box, IconButton, Avatar,  AppBar, Toolbar, Button} from '@mui/material';
import {  Notifications, CalendarToday, Add,} from '@mui/icons-material';
import CustomDataCard from '../../../../components/Button/customDataCard';
import ProductForm from './productForm';

const cardData = [
    {
      title: 'HEANML10.2 RABBIT WITH UMBRELLA WITH BORDER 4X3 FEET',
      summary: 'A framed portrayal of a rabbit with an umbrella, outlined by 457 meticulously arranged perforations.',
      price: '$744',
      sales: 1269,
      remaining: 1269,
      image: '/productManagement.svg',
    },
    {
      title: 'HEBRD19 FLYING PIGEON WITH ACTIONS 3X3 FEET',
      summary: 'Witness the grace of flight through 401 holes capturing the dynamic actions of a flying pigeon in a 3×3 feet art.',
      price: '$744',
      sales: 1269,
      remaining: 1269,
      image: '/productManagement.svg',
    },
    {
        title: 'HEBRD19 FLYING PIGEON WITH ACTIONS 3X3 FEET',
        summary: 'Witness the grace of flight through 401 holes capturing the dynamic actions of a flying pigeon in a 3×3 feet art.',
        price: '$744',
        sales: 1269,
        remaining: 1269,
        image: '/productManagement.svg',
      },
      {
        title: 'HEBRD19 FLYING PIGEON WITH ACTIONS 3X3 FEET',
        summary: 'Witness the grace of flight through 401 holes capturing the dynamic actions of a flying pigeon in a 3×3 feet art.',
        price: '$744',
        sales: 1269,
        remaining: 1269,
        image: '/productManagement.svg',
      },
      {
        title: 'HEBRD19 FLYING PIGEON WITH ACTIONS 3X3 FEET',
        summary: 'Witness the grace of flight through 401 holes capturing the dynamic actions of a flying pigeon in a 3×3 feet art.',
        price: '$744',
        sales: 1269,
        remaining: 1269,
        image: '/productManagement.svg',
      },
      {
        title: 'HEBRD19 FLYING PIGEON WITH ACTIONS 3X3 FEET',
        summary: 'Witness the grace of flight through 401 holes capturing the dynamic actions of a flying pigeon in a 3×3 feet art.',
        price: '$744',
        sales: 1269,
        remaining: 1269,
        image: '/productManagement.svg',
      },
    
    
    
  ];

  interface ProductManagementProps {
    showForm: boolean;
    setShowForm: (value: boolean) => void;
  }
  
  const ProductManagement: React.FC<ProductManagementProps> = ({ showForm, setShowForm }) => {
    return (
      <Box sx={{ display: 'flex' }}>
        <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto', display: 'flex', flexDirection: 'column' ,height:'100vh'}}>
          <AppBar position="static" elevation={0} sx={{ borderBottom: '1px solid #ddd', mb: 2, backgroundColor: 'white', color: 'black' }}>
            <Toolbar>
              <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
                Good Morning, John!
              </Typography>
              <IconButton>
                <CalendarToday />
              </IconButton>
              <Typography variant="body1" sx={{ mx: 2 }}>
                28 July, 2024
              </Typography>
              <IconButton>
                <Notifications />
              </IconButton>
              <Avatar alt="Gn" src="/profile.webp" />
            </Toolbar>
          </AppBar>
  
          {showForm ? (
            <ProductForm onBack={() => setShowForm(false)} />
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginY: 2 }}>
                <Button variant="contained" sx={{ backgroundColor: '#3f51b5' }} startIcon={<Add />} onClick={() => setShowForm(true)}>
                  ADD NEW PRODUCT
                </Button>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {cardData.map((data, index) => (
                  <CustomDataCard
                    key={index}
                    title={data.title}
                    summary={data.summary}
                    price={data.price}
                    sales={data.sales}
                    remaining={data.remaining}
                    image={data.image}
                  />
                ))}
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  };
  
  export default ProductManagement;