import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { styled } from '@mui/joy/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
// import {  Select, SelectChangeEvent, Drawer, IconButton as MuiIconButton } from '@mui/material';
import { Call, LocationOn, Close as CloseIcon } from '@mui/icons-material';
import { MenuItem, Select, SelectChangeEvent, Drawer, IconButton as MuiIconButton } from '@mui/material';
// import MenuButton from '@mui/joy';

import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
// import MenuButton from '@mui/joy/MenuButton';
// import MenuItem from '@mui/joy/MenuItem';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import colors from '../../utils/customColors';
import IconButton from '../Button/iconButton';
import SocialMediaIcon from '../Button/socialMediaIcon';

// import telegramIcon from '../../assets/socialmedia_icons/telegram.png';
// import YouTubeIcon from '../../assets/socialmedia_icons/youtube.png';
// import FacebookIcon from '../../assets/socialmedia_icons/facebook.png';
// import InstagramIcon from '../../assets/socialmedia_icons/instagram.png';
// import Whatsapp from '../../assets/socialmedia_icons/whatsapp.png';


import HotelIcon from '../../assets/socialmedia_icons/hotel.png'
import RestaurantIcon from '../../assets/socialmedia_icons/restaurant-building.png'
import FlightIcon from '../../assets/socialmedia_icons/airplane.png'
import TrainIcon from '../../assets/socialmedia_icons/train.png'
import BusIcon from '../../assets/socialmedia_icons/school-bus-front.png'
import { Button } from '@mui/joy';

interface HeaderProps {
  logo: string;
  onLoginClick: () => void;
  onSearchClick: () => void;
  onCustomizeClick: () => void;
}

const HeaderContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.5s ease-in-out',
});

const TopBar = styled(Box)({
  backgroundColor: colors.headerBg,
  padding: '8px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  height: '8vh',
  borderBottom: '1px solid #E1DDDC',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
});

const FollowUsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  marginLeft: '80px',
});

const SocialMediaRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

const MiddleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
});

const BottomBar = styled(Box)({
  backgroundColor: colors.BottomBarHeader,
  padding: '8px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '8vh',
  borderBottom: '1px solid #E1DDDC',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
});

const LogoImage = styled('img')({
  height: '70px',
  marginLeft: '50px',
});

const SearchBox = styled(TextField)({
  borderRadius: '30px',
  backgroundColor: 'transparent',
  '& .MuiInputBase-root': {
    borderRadius: '30px',
    border: `1px solid #E1DDDC`,
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D2CFCF',
  },
  width: '600px',

  '& .css-1q78djr': {
    marginLeft: '0px',  
  },
  
});

const Header: React.FC<HeaderProps> = ({ logo, onLoginClick, onSearchClick, onCustomizeClick }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [category, setCategory] = useState('All Categories');
  const [drawerOpen, setDrawerOpen] = useState(false);  
  const navigate = useNavigate();

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    setCategory(event.target.value as string);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > lastScrollTop) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollTop(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  const handleLoginClick = () => {
    setDrawerOpen(true); 
  }

  const handleOnClick=(itemName:string)=>{
    console.log("itemName",itemName)
    if(itemName=='Hotel')
    {
      window.open('https://www.booking.com/')
    }
    else if(itemName=='Flights')
    {
      window.open('https://www.makemytrip.com/')
    }
    else if(itemName == 'Trains')
    {
      window.open('https://www.irctc.co.in/')
    }

  }

  const handleLogin = () => {
    navigate('/login'); 
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  }

  return (
    <>
      <HeaderContainer style={{ transform: isVisible ? 'translateY(0)' : 'translateY(-100%)' }}>
        <TopBar>
          <FollowUsContainer>
            <SocialMediaRow>
              <SocialMediaIcon IconComponent={HotelIcon} label="Hotels" onClick={handleOnClick} name="Hotel" />
              <SocialMediaIcon IconComponent={FlightIcon} label="Flights" onClick={handleOnClick}  name="Flights" />
              <SocialMediaIcon IconComponent={RestaurantIcon} label="Restaurants"  onClick={handleOnClick} name="Restaurants" />
              <SocialMediaIcon IconComponent={TrainIcon} label="Trains" onClick={handleOnClick}  name="Trains" />
              <SocialMediaIcon IconComponent={BusIcon} label="Busses" onClick={handleOnClick}  name="Busses" />
              {/* <SocialMediaIcon IconComponent={telegramIcon} label="Telegram" />
              <SocialMediaIcon IconComponent={Whatsapp} label="WhatsApp" /> */}
            </SocialMediaRow>
          </FollowUsContainer>
          <MiddleContainer>
            <Typography sx={{ fontWeight: 'bold' }}>
              Flat <span style={{ color: 'red', fontSize: '1.5em', fontWeight: 'bold' }}>50% off</span> on first order
            </Typography>
          </MiddleContainer>

          <Select
                      value={category}
                      onChange={handleCategoryChange}
                      displayEmpty
                      sx={{
                        border: 'none',
                        outline: 'none',
                        paddingRight: '10px',
                        minWidth: '150px',
                        textAlign: 'right',
                        '& .MuiSelect-select': {
                          padding: 0,
                          display: 'flex',
                          alignItems: 'center',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .MuiSelect-icon': {
                          right: '5px',
                        },
                        boxShadow: 'none',
                      }}
                      disableUnderline
                    >
                      {/* <MenuItem value="All Categories">Language</MenuItem> */}
                      <MenuItem value="All Categories">English</MenuItem>
                      <MenuItem>Spanish</MenuItem>
                      <MenuItem>French</MenuItem>
                      <MenuItem>Chinese</MenuItem>
                      <MenuItem>Japanese</MenuItem>
                                  </Select>


          <IconButton
            title="Login"
            iconname="login"
            onPress={handleLoginClick}
            sx={{
              backgroundColor: colors.headerBg,
              color: colors.whiteText,
              '&:hover': {
                backgroundColor: colors.headerBg,
                color: colors.whiteText,
              },
              marginRight: '80px',
            }}
          />
        </TopBar>
        <BottomBar>
          <LogoImage src={logo} alt="Logo" />
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
            <Typography sx={{ lineHeight: 1, fontSize: 13, color: 'gray' }}>
              Deliver to 
            </Typography>
            <SocialMediaIcon IconComponent={LocationOn} label="533249" />
          </Box> */}
           {/* <Dropdown> */}
           {/* <MenuButton endDecorator={<ArrowDropDown />}>Language</MenuButton> */}
      {/* <Menu>
        <MenuItem>English</MenuItem>
        <MenuItem>Spanish</MenuItem>
        <MenuItem>French</MenuItem>
        <MenuItem>Chinese</MenuItem>
        <MenuItem>Japanese</MenuItem>
      </Menu> */}
    {/* </Dropdown> */}
    
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
          <SearchBox
              variant="outlined"
              placeholder="Search…"
              InputProps={{
                endAdornment: (
                  <>
                    <Select
                      value={category}
                      onChange={handleCategoryChange}
                      displayEmpty
                      sx={{
                        border: 'none',
                        outline: 'none',
                        paddingRight: '10px',
                        minWidth: '150px',
                        textAlign: 'right',
                        '& .MuiSelect-select': {
                          padding: 0,
                          display: 'flex',
                          alignItems: 'center',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .MuiSelect-icon': {
                          right: '5px',
                        },
                        boxShadow: 'none',
                      }}
                      disableUnderline
                    >
                      <MenuItem value="All Categories">All Categories</MenuItem>
                      <MenuItem value="Steel Plate">Steel Plate</MenuItem>
                      <MenuItem value="Steel Coil">Steel Coil</MenuItem>
                      <MenuItem value="Steel Pipe">Steel Pipe</MenuItem>
                      <MenuItem value="Steel Rod">Steel Rod</MenuItem>
                      <MenuItem value="Aluminium">Aluminium</MenuItem>
                      <MenuItem value="Steel Strip">Steel Strip</MenuItem>
                      <MenuItem value="Wire Rod">Wire Rod</MenuItem>
                      <MenuItem value="TMT">TMT</MenuItem>
                      <MenuItem value="Billets & Blooms">Billets & Blooms</MenuItem>
                      <MenuItem value="TMT Bars">TMT Bars</MenuItem>

                    </Select>
                    <InputAdornment position="end">
                      <SearchIcon sx={{ marginLeft: '-10px' }} />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
            <SocialMediaRow sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
                <Typography sx={{ lineHeight: 1, fontSize: 13, color: 'gray' }}>
                  Call Us
                </Typography>
                <SocialMediaIcon IconComponent={Call} label="+91 9848468556" onClick={handleOnClick} name="call"/>
              </Box>
              <SocialMediaIcon IconComponent={FavoriteBorderOutlinedIcon} label="Wishlist" onClick={handleOnClick} name="call"/>
              <Link to="/">
                <SocialMediaIcon IconComponent={ShoppingCartOutlinedIcon} label="Cart" onClick={handleOnClick} name="call"/>
              </Link>
            </SocialMediaRow>
          </Box>
        </BottomBar>
      </HeaderContainer>

      
      <Drawer
  anchor="right"
  open={drawerOpen}
  onClose={handleDrawerClose}
>
  <Box
    sx={{
      width: 350,
      p: 2,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <MuiIconButton onClick={handleDrawerClose}>
        <CloseIcon />
      </MuiIconButton>
    </Box>

    <Box sx={{ marginTop: 2 }}>
      <Typography sx={{ color: '#555555', marginBottom: 4, fontWeight: 'bold', fontSize: 30 }}>
        Hi there,
      </Typography>
      <Button
        sx={{ 
          backgroundColor: 'blue', 
          color: '#fff', 
          width: '50%', 
          padding: '10px 0',
          '&:hover': {
            backgroundColor: 'blue',
          }
        }}
       onClick={handleLogin}
      >
        Login
      </Button>
    </Box>
  </Box>
</Drawer>



    </>
  );
};

export default Header;
