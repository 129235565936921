import React, { Component,useState,useEffect,useCallback } from 'react'


export const useFetch=(url,options)=>{
    const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	console.log("baseurl",url)
	const fetchData= useCallback(async(requestBody )=>{
		setIsLoading(true)
		try {
			console.log("url",url,options)
			const response=await fetch(url,{
				...options,
				...(options.method && options.method.toUpperCase() !== 'GET' && { body: JSON.stringify(requestBody) })
			})
			const result = await response.json();
			console.log('use fetch result', result);
			if (!response.ok) {
				throw new Error(result.message || 'Something went wrong');
			}
			setData(result);
			setError(null);
		} catch (error) {
			setError(error.message || 'Something went wrong');
		} finally {
			setIsLoading(false);
		
		}},[url,options])

	


		return { data, error, isLoading, fetchData };
   


}