import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

interface Review {
  name: string;
  review: string;
  company: string;
}

const reviews: Review[] = [
  {
    name: 'Gowri Sound & Light Service',
    review: 'They are beautifully decorated and have lovely sound systems.',
    company: 'Gowri Sound & Light Service',
  },
  {
    name: 'ASH Sounds and Lights',
    review: 'The lighting and decorations were superb, thanks to Hero Electro.',
    company: 'ASH Sounds and Lights',
  },
  {
    name: 'Anbu Audios & Decors',
    review: 'Excellent experiences with the services of Pixel LED Design and Hero Sounds.',
    company: 'Anbu Audios & Decors',
  },
];

const CustomerReview: React.FC = () => {
  return (
    <Box sx={{ padding: '40px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Customer Review
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        What our Customers say about Hero Electro
      </Typography>
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        {reviews.map((review, index) => (
          <Grid
            item
            xs={12}
            sm={index === 1 ? 10 : 4}
            md={index === 1 ? 4 : 3}
            key={index}
            sx={{
              position: 'relative',
              zIndex: index === 1 ? 2 : 1,
              transform: index !== 1 ? 'scale(0.95)' : 'scale(1)',
              marginTop: index !== 1 ? '20px' : '0',
              marginLeft: index === 2 ? '-40px' : '0',
              marginRight: index === 0 ? '-40px' : '0',
              background: index === 1 ? 'linear-gradient(270deg, #C3F6E3 0%, #E7E1FC 33.33%, #EFCAD9 66.67%, #F6F4CF 100%)' : 'transparent',
              borderRadius: '16px',
            }}
          >
            <Paper
              elevation={index === 1 ? 6 : 2}
              sx={{
                padding: '30px',
                borderRadius: '16px',
                backgroundColor: 'white',
                border: index === 1 ? '6px solid transparent' : 'none',
                backgroundImage:
                  index === 1
                    ? 'linear-gradient(white, white), radial-gradient(circle at top left, #ffc107, #8e24aa, #00acc1)'
                    : 'none',
                backgroundOrigin: 'border-box',
                backgroundClip: index === 1 ? 'padding-box, border-box' : 'border-box',
                '&:hover': {
                  transform: index === 1 ? 'scale(1.05)' : 'scale(1)',
                  boxShadow: index === 1 ? '0 8px 24px rgba(0, 0, 0, 0.2)' : '0 4px 12px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <Box display="flex" justifyContent="center" mb={1}>
                {Array.from({ length: 5 }, (_, i) => (
                  <StarIcon key={i} sx={{ color: 'gold' }} />
                ))}
              </Box>
              <Typography variant="body1" mb={2}>
                {review.review}
              </Typography>
              <Typography variant="h6" color="text.secondary">
                {review.company}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CustomerReview;
