import React from 'react';
import { Box, Typography, Grid, Link, Container, Button } from '@mui/material';
import whatsappIcon from '../../assets/socialmedia_icons/whatsapp.png';
import telegramIcon from '../../assets/socialmedia_icons/telegram.png';
import instagramIcon from '../../assets/socialmedia_icons/instagram.png';
import gmailIcon from '../../assets/socialmedia_icons/gmail.png';
import Play from '../../assets/images/Play.png'; 
import App from '../../assets/images/App.png'; 


interface textProps {
  logoAlt: string;
  companyName: string;
  companyDescription: string;
  reachUsTitle: string;
  reachUsTime: string[];
  customerServiceTitle: string;
  customerServiceLinks: { text: string; href: string }[];
  usefulHintsTitle: string;
  usefulHintsLinks: { text: string; href: string }[];
  helpDeskTitle: string;
  helpDeskLinks: { text: string; href: string }[];
}

const footerProps: textProps = {
  logoAlt: 'Logo',
  companyName: 'STEEL EXCHNAGES',
  companyDescription: 'Block-A, Greencity, Near Apparel Export Park Vadlapudi Post, Visakhapatnam-530046 ,Andhra Pradesh, India.',
  reachUsTitle: 'Reach Us',
  reachUsTime: ['Monday – Saturday', '08:00 AM – 10:00 PM'],
  customerServiceTitle: 'About',
  customerServiceLinks: [
    { text: 'Company', href: '#' },
    { text: 'Leadership', href: '#' },
    { text: 'History', href: '#' },
    { text: 'Locations', href: '#' },
    { text: 'Safety', href: '#' },
    { text: 'Public Affairs', href: '#' },
  ],
  usefulHintsTitle: 'Investors',
  usefulHintsLinks: [
    { text: 'SEC Filings', href: '#' },
    { text: 'ESG', href: '#' },
    { text: 'Financial Information', href: '#' },
  ],
  helpDeskTitle: 'Resources',
  helpDeskLinks: [
    { text: 'News & Content', href: '#' },
    { text: 'Media Kit', href: '#' },
    { text: 'Transportation & Logistics', href: '#' },
    { text: 'Certifications & SDS', href: '#' },
  ],
};

const Footer: React.FC<textProps> = ({
  logoAlt,
  companyName,
  companyDescription,
  reachUsTitle,
  reachUsTime,
  customerServiceTitle,
  customerServiceLinks,
  usefulHintsTitle,
  usefulHintsLinks,
  helpDeskTitle,
  helpDeskLinks,
}) => {
  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        px: 2,
        mt: 'auto',
        backgroundColor: '#212529',
        color: 'white',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            {/* <img src={require('../../assets/logo-10q.png')} alt={logoAlt} width={150} /> */}
            <Typography variant="h6" sx={{ color: 'white' }}>
              {companyName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {companyDescription}
            </Typography>

            {/* Contact Us Button */}
            {/* <Box mt={2}>
              <Button variant="contained" sx={{ backgroundColor: 'black', color: 'white' }}>
                Contact Us
              </Button>
            </Box> */}

            {/* Social Media Icons */}
            <Box mt={2} sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <Link href="#"><img src={whatsappIcon} alt="WhatsApp" style={{ width: 24, height: 24 }} /></Link>
              <Link href="#"><img src={instagramIcon} alt="Instagram" style={{ width: 24, height: 24 }} /></Link>
              <Link href="#"><img src={telegramIcon} alt="Telegram" style={{ width: 24, height: 24 }} /></Link>
              <Link href="#"><img src={gmailIcon} alt="Gmail" style={{ width: 24, height: 24 }} /></Link>
            </Box>
          </Grid>
          
          {/* Other Sections */}
          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            // sx={{ borderRight: '1px solid white' }} // Right border for "About" section
          >
            <Typography variant="h6" sx={{ color: 'white' }}>{customerServiceTitle}</Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {customerServiceLinks.map((link, index) => (
                <React.Fragment key={index}>
                  <Link href={link.href} sx={{ color: 'white', textDecoration: 'none' }}>{link.text}</Link><br />
                </React.Fragment>
              ))}
            </Typography>
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            // sx={{ borderRight: '1px solid white' }} // Right border for "Investors" section
          >
            <Typography variant="h6" sx={{ color: 'white' }}>{usefulHintsTitle}</Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {usefulHintsLinks.map((link, index) => (
                <React.Fragment key={index}>
                  <Link href={link.href} sx={{ color: 'white', textDecoration: 'none' }}>{link.text}</Link><br />
                </React.Fragment>
              ))}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" sx={{ color: 'white' }}>{helpDeskTitle}</Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {helpDeskLinks.map((link, index) => (
                <React.Fragment key={index}>
                  <Link href={link.href} sx={{ color: 'white', textDecoration: 'none' }}>{link.text}</Link><br />
                </React.Fragment>
              ))}
            </Typography>
          </Grid>


 {/* App Download Section */}
 <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ color: 'white', mb: 0 }}>App</Typography>
   
            <Box sx={{ display: 'flex', gap: 0,flexDirection:'column' }}>
              <Link href= "https://play.google.com" target='_blank'><img src={Play} alt="Get it on Google Play" style={{ width: 135 }} /></Link>
              <Link href="https://www.apple.com/" target='_blank'><img src={App} alt="Available on the App Store" style={{ width: 135 }} /></Link>
            </Box>
           
            
          </Grid>



        </Grid>
        

          {/* Bottom Copyright Section */}
          <Box mt={4} sx={{ textAlign: 'center',borderTopWidth:1 }}>
          <Typography variant="body2" sx={{ color: 'white' }}>
            ©2024 Stell Exchanges. All Rights Reserved. Powered by Fortaze
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

const FooterWithProps = () => <Footer {...footerProps} />;

export default FooterWithProps;
