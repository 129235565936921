import { useState} from 'react';
import { Box, Avatar, IconButton, Toolbar, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,AppBar } from '@mui/material';
import { CalendarToday, Notifications } from '@mui/icons-material';

const orderData = [
  { id: '1', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '2', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '3', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '4', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View'},
  { id: '5', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View'},
  { id: '6', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View'},
  { id: '7', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View'},
  { id: '8', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View'},
  { id: '9', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View'},
  { id: '10', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View'},
  { id: '11', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View'},
  { id: '12', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View'},
  { id: '13', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View'},
  
];


const CustomerMnagement: React.FC = () => {
  const [searchValue] = useState('');
 
 
  const filteredOrders = orderData.filter(order =>
    order.id.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static" elevation={0} sx={{ borderBottom: '1px solid #ddd', backgroundColor: 'white', color: 'black' }}>
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            Customer Management
          </Typography>
          <IconButton>
            <CalendarToday />
          </IconButton>
          <Typography variant="body1" sx={{ mx: 2 }}>
            28 July, 2024
          </Typography>
          <IconButton>
            <Notifications />
          </IconButton>
          <Avatar alt="Gn" src="/profile.webp" />
        </Toolbar>
      </AppBar>

      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto' }}>
       

        

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>NAME</TableCell>
                <TableCell>PHONE NUMBER</TableCell>
                <TableCell>CREATED</TableCell>
                <TableCell>ACTION</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((order, index) => (
                <TableRow key={index}>
                  
                  <TableCell>{order.created}</TableCell>
                  <TableCell>{order.customer}</TableCell>
                  <TableCell>{order.total}</TableCell>
                  <TableCell>
                    {order.profit} 
                  
                  </TableCell>
                  <TableCell>
             
                    
                  </TableCell>
                 
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Typography variant="body2">Showing {filteredOrders.length} of {orderData.length}</Typography>
          <Box>
            <IconButton sx={{ color: '#4F46E5' }}>⟨</IconButton>
            {[1, 2, 3, 4, 5].map((page) => (
              <IconButton key={page} sx={{ color: '#4F46E5' }}>{page}</IconButton>
            ))}
            <IconButton sx={{ color: '#4F46E5' }}>⟩</IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerMnagement;
