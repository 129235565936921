import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import styled from '@emotion/styled';

interface CustomCardProps {
  title: string;
  image: string;
  originalPrice: string;
  discountedPrice: string;
  discount: string;
}
const CustomAspectRatio = styled(AspectRatio)`
  .css-6e7lq1-JoyAspectRatio-content {
    position: static !important;
  },
  
`;

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  image,
  originalPrice,
  discountedPrice,
  discount
}) => {
  return (
    <Card sx={{ width: 200, maxWidth: '100%', boxShadow: 'lg', margin: 3 }}>
      <CardOverflow>
        <CustomAspectRatio sx={{ minHeight: 250, maxHeight: 250,position:'static' }}>
          <img
            src={image}
            loading="lazy"
            alt={title}
          
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', 
             
            }}
          />
        </CustomAspectRatio>
      </CardOverflow>
      <CardContent>
        <Typography level="body-xs">{title}</Typography>
        <Typography
          level="title-lg"
          sx={{ mt: 1, fontWeight: 'xl', color: '#F03E3E' }}
          endDecorator={
            <Chip component="span" size="sm" variant="soft" color="success">
              {discount}
            </Chip>
          }
        >
          {discountedPrice}
        </Typography>
        <Typography level="body-sm" sx={{ textDecoration: 'line-through' }}>
          {originalPrice}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CustomCard;
