import React, { useState } from 'react';
import { Box, Stack, Typography, Input, Button, Divider, Modal, IconButton } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom'; 

const YourOrder: React.FC = () => {
  const [discountCode, setDiscountCode] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); 
  const navigate = useNavigate();

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(event.target.value);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOrderStatusClick = () => {
    setIsModalOpen(false); 
    navigate('/order-tracking'); 
  };

  return (
    <>
      <Box
        sx={{
          p: 3,
          width: '100%',
          maxWidth: '380px',
          height: '100%',
          maxHeight: '900px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          bgcolor: '#fff',
          ml: 4,
        }}
      >
        <Stack spacing={2}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem', mb: 2 }}>Your Order</Typography>

          
          <Stack spacing={2}>
            {[1, 2, 3].map((_, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component="img" src="./productManagement.svg" alt="Product" sx={{ width: '70px', height: '70px', mr: 2 }} />
                <Box>
                  <Typography>HEPC11.3 SIMPLE PEACOCK HOLDING CHAIN ON MOUTH 6X3 FEET</Typography>
                  <Typography sx={{ fontSize: '0.875rem', color: '#757575' }}>1 X ₹1,030.00</Typography>
                </Box>
              </Box>
            ))}
          </Stack>

          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Input placeholder="discount code" value={discountCode} onChange={handleDiscountChange} fullWidth />
            <Button variant="solid" color="primary">Apply</Button>
          </Box>

          
          <Stack spacing={1} mt={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ color: 'gray' }}>Subtotal</Typography>
              <Typography sx={{ color: 'gray' }}>$519.52</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ color: 'gray' }}>Discount</Typography>
              <Typography sx={{ color: 'gray' }}>-$111.87</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ color: 'gray' }}>Shipment cost</Typography>
              <Typography sx={{ color: 'gray' }}>$22.50</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', mt: 2 }}>
              <Typography>Grand Total</Typography>
              <Typography>$543.02</Typography>
            </Box>
          </Stack>

          <Button variant="solid" color="primary" sx={{ mt: 2, width: '100%', py: 1.5 }} onClick={handleOpenModal}>
            Continue to pay
          </Button>
        </Stack>
      </Box>

      
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            width: 400,
            p: 3,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            bgcolor: '#fff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Stack alignItems="center" spacing={2}>
            <CheckCircleIcon sx={{ fontSize: 60, color: 'green' }} />
            <Typography sx={{ fontSize: 24, fontWeight: 'bold', color: 'green' }}>Successful Payment</Typography>
            <Stack spacing={1} sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Payment type</Typography>
                <Typography>Net Banking</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Phone number</Typography>
                <Typography>+12345678910</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Email</Typography>
                <Typography>GN1996@gmail.com</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Transaction id</Typography>
                <Typography>2345678910</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', mt: 2 }}>
                <Typography>Amount Paid</Typography>
                <Typography>$543.02</Typography>
              </Box>
            </Stack>
            <Button variant="solid" color="primary" sx={{ mt: 2, width: '100%' }} onClick={handleOrderStatusClick}>
              Order Status
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default YourOrder;
