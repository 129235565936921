export const urls=(type)=>{ 
    switch (type) {
        case 'login':
            return 'api/v1/auth/login/'
        case 'register':
            return 'api/v1/auth/register/'   
        case 'addAddress':
            return 'api/v1/auth/add_address/'
        case 'test':
            return 'api/v1/auth/test/'
        default:
            break;
    }
    
   

}