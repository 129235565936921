import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia, IconButton, Divider, LinearProgress } from '@mui/material';
import { ArrowUpward, MoreVert } from '@mui/icons-material';

interface CustomDataCardProps {
  title: string;
  summary: string;
  price: string;
  sales: number;
  remaining: number;
  image: string;
}

const CustomDataCard: React.FC<CustomDataCardProps> = ({ title, summary, price, sales, remaining, image }) => {
  return (
    <Card sx={{ maxWidth: 360, margin: 2, borderRadius: 2, boxShadow: 3 }}>
      <CardContent sx={{ padding: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <CardMedia
            component="img"
            sx={{ width: 80, height: 80, borderRadius: 1 }}
            image={image}
            alt={title}
          />
           <Typography sx={{ fontSize: 16, fontWeight: 'bold', marginBottom: 1 ,ml:2}}>
          {title}
        </Typography>
          <IconButton>
            <MoreVert />
          </IconButton>
        </Box>
       
        <Typography sx={{ fontSize: 20, fontWeight: 'bold', marginBottom: 1 }}>
          {price}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Summary
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
          {summary}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="body2" color="text.secondary">
              Sales
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'orange' }}>
              <ArrowUpward fontSize="small" />
              <Typography variant="body2" sx={{ marginLeft: 0.5 }}>{sales}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="body2" color="text.secondary">
              Remaining Products
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LinearProgress
                variant="determinate"
                value={remaining / (sales + remaining) * 100}
                sx={{ width: 50, height: 8, borderRadius: 5, marginRight: 0.5, bgcolor: 'lightgray', '& .MuiLinearProgress-bar': { bgcolor: '#FFA52F' } }}
              />
              <Typography variant="body2">{remaining}</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomDataCard;
