import { Stack } from '@mui/joy';
import React, { useState, useEffect, useRef } from 'react';
import Cards from './cards';
import Categories from './categories';
import Banner from './banner';
import CustomerReviews from './customer-reviews';
import Globe from 'react-globe.gl';
import * as THREE from 'three'; // Import Three.js
import { location } from '../../assets/images';
interface Place {
  properties: {
    name: string;
    latitude: number;
    longitude: number;
    pop_max: number;
  };
}

interface PointData {
  lat: number;
  lng: number;
  name: string;
  pop_max: number;
}

const Home: React.FC = () => {
  const globeEl = useRef<any>(null); // Ref for the globe

  const [, setSelectedCategory] = useState<string | null>(null);
  const [places, setPlaces] = useState<Place[]>([]);
  const [pointData, setPointData] = useState<PointData | null>(null);

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    fetch('../datasets/ne_110m_populated_places_simple.geojson')
      .then((res) => res.json())
      .then(({ features }) => setPlaces(features));
  }, []);

  const handlePointClick = (
    point: object,
    e: MouseEvent,
    cords: { lat: number; lng: number; altitude: number }
  ) => {
    const p = point as PointData;
    console.log('point', p.name);
    if (p.name === 'Russia') {
      window.open('https://lipetsk.nlmk.com/en/', '_blank');
    } else if (p.name === 'Japan') {
      window.open('https://www.nipponsteel.com/', '_blank');
    } else if (p.name === 'USA') {
      window.open('https://nucor.com/', '_blank');
    } else if (p.name === 'Germany') {
      window.open('https://www.thyssenkrupp.com/en/home', '_blank');
    } else if (p.name === 'Sweden') {
      window.open('https://www.ssab.com/en', '_blank');
    } else if (p.name === 'India') {
      window.open('https://www.tatasteel.com/', '_blank');
    }
  };

  const pointsData: PointData[] = [
    { lat: 61.5240, lng: 105.3188, name: 'Russia', pop_max: 870887 },
    { lat: 36.2048, lng: 138.2529, name: 'Japan', pop_max: 3980400 },
    { lat: 38.7946, lng: 106.5348, name: 'USA', pop_max: 3980400 },
    { lat: 51.1657, lng: 10.4515, name: 'Germany', pop_max: 3980400 },
    { lat: 60.1282, lng: 18.6435, name: 'Sweden', pop_max: 3980400 },
    { lat: 20.5937, lng: 78.9629, name: 'India', pop_max: 3980400 },
  ];

  // Load the marker icon texture
  const markerTexture = new THREE.TextureLoader().load(location); // Path to the uploaded image

  useEffect(() => {
    if (!globeEl.current) return;

    const size = 10; // Adjust the size of the marker
    const markerMaterial = new THREE.SpriteMaterial({ map: markerTexture });

    pointsData.forEach(({ lat, lng }) => {
      const markerSprite = new THREE.Sprite(markerMaterial);
      markerSprite.scale.set(size, size, 1); // Set size of marker

      const { x, y, z } = globeEl.current.getCoords(lat, lng, 0.2); // altitude = 0.2
      markerSprite.position.set(x, y, z);

      globeEl.current.scene().add(markerSprite);
    });
  }, [pointsData]);

  return (
    <Stack>
      <Globe
        ref={globeEl} // Reference to access the globe object
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
        backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
        pointsData={pointsData}

        // Customizing the points (just in case)
        pointAltitude={(d: object) => 0.1} // Adjust altitude if needed
        pointRadius={(d: object) => 0.4} // Adjust radius if needed
        pointColor={(d: object) => 'red'} // Adjust color if needed

        onPointClick={handlePointClick}
        onGlobeClick={(coords) => {
          console.log('Globe clicked at:', coords);
        }}
        onLabelClick={(label, e, coords) => {
          console.log('Label clicked:', label);
          console.log('Coordinates:', coords);
        }}
      />

      <Cards />
      <Banner />
      <CustomerReviews />
    </Stack>
  );
};

export default Home;
