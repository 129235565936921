import * as React from 'react';
import { Box, Stack, Typography, Input, Button, Checkbox, Divider } from '@mui/joy';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const OrderTracking: React.FC = () => {
  const orderDetails = {
    orderNo: '34BV66580K92',
    shippedVia: 'UPS Ground',
    shippedOn: 'August 29, 2020, 6:00 pm',
    destination: 'London, United Kingdom',
    expectedDate: 'September 4, 2020',
    status: 'In Transit',
  };

  const trackingSteps = [
    { status: 'Order Placed', location: 'New York, USA', date: 'August 29, 2020', time: '2:00 pm', completed: true },
    { status: 'Documentation Prepared', location: 'New York, USA', date: 'August 29, 2020', time: '3:30 pm', completed: true },
    { status: 'Booking Arranged', location: 'New York, USA', date: 'August 29, 2020', time: '3:35 pm', completed: true },
    { status: 'Collected', location: 'New York, USA', date: 'August 29, 2020', time: '4:00 pm', completed: true },
    { status: 'In Transit to Destination', location: 'New York, USA', date: 'August 29, 2020', time: '6:00 pm', completed: true },
    { status: 'Arrived at Destination', location: 'London, United Kingdom', date: '', time: '', completed: false },
    { status: 'Out for Delivery', location: 'London, United Kingdom', date: '', time: '', completed: false },
    { status: 'Delivered', location: 'London, United Kingdom', date: '', time: '', completed: false },
  ];

 
  const activeStepIndex = trackingSteps.findIndex((step) => !step.completed);

  return (
    <Box sx={{ p: 4, maxWidth: 1200, margin: '0 auto', display: 'flex', flexDirection: 'row', gap: 4 }}>
      
      
      <Box sx={{ width: '35%' }}>
        <Typography level="h2" sx={{ mb: 2, fontWeight: 'bold' ,fontSize:40}}>
          Track your order
        </Typography>
        <Typography sx={{ mb: 4 }}>
          This form allows you to search for tracking details from anywhere within Tracking Service.
        </Typography>

        <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 4 }}>
          <Input placeholder="Order No" defaultValue={`# ${orderDetails.orderNo}`} sx={{ flex: 1 }} />
          <Button variant="solid" color="primary">
            Search
          </Button>
        </Stack>

        <Box sx={{ p: 3, borderRadius: '8px', boxShadow: 'sm', bgcolor: '#fff', mb: 8,mt:8 }}>
          <Typography sx={{ fontWeight: 'bold', mb: 2 }}>Order No: {orderDetails.orderNo}</Typography>
          <Typography>Shipped via: {orderDetails.shippedVia}</Typography>
          <Typography>Shipped on: {orderDetails.shippedOn}</Typography>
          <Typography>Destination: {orderDetails.destination}</Typography>
          <Typography>Expected date: {orderDetails.expectedDate}</Typography>
          <Typography>Status: {orderDetails.status}</Typography>
        </Box>

        <Checkbox label="Notify me when order is delivered" sx={{ mb: 4 }} />
      </Box>

      
      <Box sx={{ width: '65%' }}>
        <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
          <Typography sx={{ fontWeight: 'bold', width: '30%' }}>Status</Typography>
          <Typography sx={{ fontWeight: 'bold', width: '30%' }}>Location</Typography>
          <Typography sx={{ fontWeight: 'bold', width: '20%' }}>Date</Typography>
          <Typography sx={{ fontWeight: 'bold', width: '20%' }}>Time</Typography>
        </Stack>
        <Divider sx={{ mb: 2 }} />
        <Stepper
          orientation="vertical"
          sx={{
            '--Stepper-verticalGap': '1.5rem',
            '--StepIndicator-size': '1rem',
            '--Step-gap': '1.5rem',
            '--Step-connectorInset': '0rem',
            '--Step-connectorRadius': '1rem',
            '--Step-connectorThickness': '2px',
            '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
            [`& .${stepClasses.completed}`]: {
              '&::after': { bgcolor: 'success.solidBg' },
            },
            [`& .${stepClasses.active}`]: {
              [`& .${stepIndicatorClasses.root}`]: {
                border: '3px solid',
                borderColor: '#fff',
                boxShadow: (theme) => `0 0 0 1px ${theme.vars.palette.primary[500]}`,
              },
            },
            [`& .${stepClasses.disabled} *`]: {
              color: 'neutral.softDisabledColor',
            },
          }}
        >
          {trackingSteps.map((step, index) => (
            <Step
              key={index}
              completed={step.completed}
              active={index === activeStepIndex}
              disabled={index > activeStepIndex}
              indicator={
                <StepIndicator variant="solid" color={step.completed ? 'success' : index === activeStepIndex ? 'primary' : 'neutral'}>
                  {step.completed ? <CheckRoundedIcon fontSize="small" /> : <LocalShippingIcon fontSize="small" />}
                </StepIndicator>
              }
            >
              <Box>
                <Stack direction="row" spacing={2}>
                  <Typography sx={{ width: '30%', fontWeight: step.completed ? 'bold' : 'normal', color: step.completed ? 'black' : '#9E9E9E' }}>
                    {step.status}
                  </Typography>
                  <Typography sx={{ width: '30%', color: step.completed ? 'black' : '#9E9E9E' }}>{step.location}</Typography>
                  <Typography sx={{ width: '20%', color: step.completed ? 'black' : '#9E9E9E' }}>{step.date}</Typography>
                  <Typography sx={{ width: '20%', color: step.completed ? 'black' : '#9E9E9E' }}>{step.time}</Typography>
                </Stack>
              </Box>
              {index < trackingSteps.length - 1 && (
                <Divider sx={{ mb: 2, ml: 2, borderColor: trackingSteps[index + 1].completed ? '#4CAF50' : '#BDBDBD' }} />
              )}
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default OrderTracking;
